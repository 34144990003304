<template>
  <div class="food-search">
    <van-search
    v-model="keyword"
    shape="round"
    placeholder="请输入食物名称"
    @clear="onCancel"
    @keyup="resetData"
    @search="onSearch"/>

    <transition name="fade">
      <div v-show="!showSearchResult">
        <div class="search-history" v-if="foodTagsHistory.length">
          <div class="history-title">
            <h4 class="title-content">搜索历史</h4>
            <button @click="clear"><van-icon name="delete"  size="12"/>清空</button>
          </div>

          <div class="food-tag">
            <span v-for="(tag, index) of foodTagsHistory" :key="index" @click="onSearch(tag.tag)">{{ tag.tag }}</span>
          </div>
        </div>

        <div class="often-search">
          <h4 class="title-content">大家都在搜</h4>
          <div class="food-tag">
            <span v-for="(tag, index) of foodTags" :key="index" @click="onSearch(tag)">{{ tag }}</span>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-show="showSearchResult" class="search-result">
      <van-list
        v-model="loading"
        :finished="isFinished"
        finished-text="没有更多了"
        @load="loadMore"
      >
        <ul v-if="searchResult.length">

          <li v-for="item of searchResult" :key="item.id">
            <router-link :to="{ name: 'foodNutritionDetail', query: { id: item.id } }">
              <van-image
                width="42"
                height="42"
                :src="item.image_url"
                :error-icon="require('../../../public/imgs/nutritionQuery/food_default_cover.png')"
              />
              <div class="result-info">
                  <p>{{ item.name }}</p>
                  <p><span> {{ item.enerc_cal}}</span>卡/100克</p>
              </div>
            </router-link>
          </li>
        </ul>
        </van-list>
        <van-empty v-if="searchResult.length == 0">
          <div slot="description" class="empty-description">
            <p>抱歉，没有找到“输入内容”相关的食物!</p>
            <p>建议您：</p>
            <p>• 请您检查是否输入了错误的食物名称；</p>
            <p>• 您也可以尝试输入该食物的其他名称</p>
            <p>进行查询。</p>
          </div>
        </van-empty>
      </div>
    </transition>
  </div>
</template>

<script>
  import Dexie from 'dexie';
  export default {
    name: 'foodSearch',
    data() {
      return {
        page : 1,
        totalPage:1,
        isFinished : false,
        keyword: '',
        loading : false,
        foodTags: [],
        foodTagsHistory:[],
        showSearchResult: false,
        searchResult: [],
        lastKeywords :""
      }
    },
    mounted(){
        document.title = "食品营养素查询";
        this.initSearchHistory()
        this.getRecommendKeywrods();
    },
    methods: {
        resetData(){
            if(this.lastKeywords == this.keyword){
                return
            }
            this.page             = 1;
            this.totalPage        = 1;
            this.isFinished       = false;
            this.loading          = false;
        },
        clear(){
            var that = this;
            this.db.keyword.clear().then(function(){
                that.initSearchHistory();
            })
        },
        initSearchHistory(){
            this.db = new Dexie("search_history");
            this.db.version(1).stores({
                keyword : "++id,&tag"
            });
            var that = this;
            this.db.keyword.where('id').above(0).limit(20).distinct().toArray().then(function(keywords){
                that.foodTagsHistory = keywords.reverse();
            });

        },
        onCancel(){
            //this.initSearchHistory();
            ///this.showSearchResult = false;
        },
        onSearch(keyword) {
            keyword = keyword.trim();
            this.lastKeywords = keyword;
            this.db.keyword.add({tag:keyword});
            var that = this;
            if(keyword == ""){
                keyword = that.keyword;
            } else {
                that.keyword = keyword;
            }
          this.$ajax.post(this.API.api.apiurl.getFoodList, {
              name:keyword,
              page:that.page,
          },{}).then(res => {
              var data = res.data;
              that.showSearchResult = true;
              if(data.code != 0){
                  that.searchResult = [];
                  return;
              }
              if(data.data.data.length == 0){
                  that.searchResult = [];
                  return;
              }
              that.searchResult = data.data.data;
              that.totalPage = data.data.total_page;
          });
        },
        loadMore() {
            var that = this;
            that.loading =true;
            that.id = this.$route.query.id;
            if(that.page >= that.totalPage){
                that.isFinished = true;
                return;
            }
            that.page++;
            this.$ajax.post(this.API.api.apiurl.getFoodList, {
                name:that.keyword,
                page:that.page,
            },{}).then(res => {
                that.loading =false;
                that.searchResult= that.searchResult.concat(res.data.data.data);
                that.showSearchResult = true;
            });
        },
      getRecommendKeywrods(){
            var that = this;
            that.id = this.$route.query.id;
            this.$ajax.post(this.API.api.apiurl.getFoodRecommendKeywrods, {},{
            }).then(res => {
                that.foodTags = res.data.data;
            });
      }
    }
  }
</script>

<style lang="scss">
    #app, body, html{
        overflow-x: visible !important;
    }
.food-search {
  position: relative;
  height: calc(100vh - 72px);

  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .van-search {
    padding: 10px 20px;

    .van-search__content {
      background-color: #F5F5F5;
    }
  }

  .search-history {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin-top: 22px;

    .history-title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #979797;
        font-size: 12px;
        background-color: #fff;
      }
    }
  }

  .often-search {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin-top: 32px;
  }

  .title-content {
    font-size: 16px !important;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141414;
    line-height: 23px;
    text-align: left;
  }

  .food-tag {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 15px;

    span {
      background-color: #F5F5F5;
      border-radius: 13px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 17px;
      padding: 5px 10px;
      margin-right: 10px;
      margin-bottom: 10px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .search-result {
    position: absolute;
    left: 0;
    top: 54px;
    width: 100%;
    z-index: 2;
    margin-top: 12px;

    ul {
      display: flex;
      flex-direction: column;
      padding-left: 20px;

      li {
        margin-bottom: 12px;

        a {
          display: flex !important;
          justify-content: center;
        }

        p:first-of-type {
          margin-bottom: 4px;
        }

        .van-image {
          margin-right: 16px;
        }

        .result-info {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          flex: 1;
          height: 54px;
          border-bottom: 1px solid #E6E6E6;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 17px;
          text-align: left;
          padding-bottom: 12px;

          span {
            color: #C25E4D;
          }
        }
      }
    }

    .empty-description {
      p {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 19px;
        text-align: left;
      }

      p:first-of-type {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 17px;
        margin-bottom: 14px;
      }
    }
  }
}
</style>
